import React, { lazy } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";

interface NavigationItem {
  label: string;
  path: string;
}

const navigationData: NavigationItem[] = [
  { label: "Home", path: "/" },
  { label: "Our Team", path: "/our_Team" },
  { label: "Doyenify Agency", path: "/doyenify_Agency" },
  { label: "Graphics Design", path: "/graphics_Design" },
  { label: "UIUX Design", path: "/ui_uxDesign" },
  { label: "BrandIdentity Design", path: "/brand_identity" },
  { label: "Web Dev", path: "/web_development" },
  { label: "App Dev", path: "/app_development" },
  { label: "Software Dev", path: "/softwaredevelopment" },
  { label: "Coming Soon", path: "/coming-soon" },
  { label: "Terms and Conditions", path: "/termsandconditions" },
  { label: "PrivacyPolicy", path:  "/PrivacyPolicy"}, 
];

// Lazy load pages
const HomeView = lazy(() => import("./pages/Homepage"));
const AboutUsView = lazy(() => import("./pages/AboutUs"));
const ContactView = lazy(() => import("./pages/Contact"));
const OurTeamView = lazy(() => import("./pages/OurTeam"));
const DoyenifyAgencyView = lazy(() => import("./pages/DoyenifyAgency"));
const GraphicsDesignView = lazy(() => import("./pages/GraphicsDesign"));
const UIUXDesignView = lazy(() => import("./pages/UIUXDesign"));
const BrandDesignView = lazy(() => import("./pages/BrandIdentity"));
const WebDevelopment = lazy(() => import("./pages/WebDev"));
const AppDevelopment = lazy(() => import("./pages/AppDev"));
const SoftwareDevelopment = lazy(() => import("./pages/SoftwareDev"));
const ComingSoonView = lazy(() => import("./pages/ComingSoon"));
const TermsAndConditions = lazy(() => import("./pages/Termsandconditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"))

const App: React.FC = () => (
  <>
    <Header navigation={navigationData} />
    <Container fluid className="px-0">
      <React.Suspense>
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/about_us" element={<AboutUsView />} />
          <Route path="/contact" element={<ContactView />} />
          <Route path="/our_team" element={<OurTeamView />} />
          <Route path="/doyenifyAgency" element={<DoyenifyAgencyView />} />
          <Route path="/graphicsDesign" element={<GraphicsDesignView />} />
          <Route path="/ui_uxDesign" element={<UIUXDesignView />} />
          <Route path="/brand_identity" element={<BrandDesignView />} />
          <Route path="/web_development" element={<WebDevelopment />} />
          <Route path="/app_development" element={<AppDevelopment />} />
          <Route path="/coming-soon" element={<ComingSoonView />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

          <Route
            path="/softwaredevelopment"
            element={<SoftwareDevelopment />}
          />
        </Routes>
      </React.Suspense>
    </Container>
    {/* <Card /> */}
    <Footer />
  </>
);

const AppWrapper: React.FC = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWrapper;
