
if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
}

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AppWrapper from "./App";
import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById("root")
);
