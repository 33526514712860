import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import "./header.css";
import "./dropdownMenu.css";
import {
  ChevronDown,
  Linkedin,
  Facebook,
  Instagram,
} from "react-bootstrap-icons";

interface Props {
  label: string;
  path: string;
}

interface HeaderProps {
  navigation: Props[];
}

const Header: React.FC<HeaderProps> = ({ navigation }) => {
  const navigate = useNavigate();
  const [dropDown, setDropDown] = useState(false);
  const [companyDropDown, setCompanyDropDown] = useState(false);
  const [expanded, setExpanded] = useState(false); // State to manage navbar collapse

  const dropDownRef = useRef<HTMLDivElement>(null);
  const companyDropDownRef = useRef<HTMLDivElement>(null);

  const toggleChevron = () => {
    setDropDown(!dropDown);
    setCompanyDropDown(false); // Close other dropdown if it's open
  };

  const toggleCompanyChevron = () => {
    setCompanyDropDown(!companyDropDown);
    setDropDown(false); // Close other dropdown if it's open
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as Node) &&
      companyDropDownRef.current &&
      !companyDropDownRef.current.contains(event.target as Node)
    ) {
      setDropDown(false);
      setCompanyDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (path: string, isExternal?: boolean) => {
    if (isExternal) {
      window.open(path, "_blank");
    } else {
      navigate(path);
    }
    setDropDown(false);
    setCompanyDropDown(false);
    setExpanded(false); // Close the navbar after selecting
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={{ backgroundColor: "#FFFFFF", width: "100vw" }}
      variant="dark"
      fixed="top"
      expanded={expanded} // Bind expanded state to Navbar
    >
      <Container>
        <Navbar.Brand onClick={() => navigate("/")}>
          <img
            alt="doyenify logo"
            src="./img/doyen1.png"
            width="120"
            height="auto"
            className="d-inline-block align-top"
            style={{ cursor: "pointer" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)} // Toggle expanded state on click
          style={{
            filter:
              "invert(40%) sepia(37%) saturate(561%) hue-rotate(81deg) brightness(91%) contrast(94%)",
          }}
        />
        <Navbar.Collapse id="responsive-navbar-nav" className="Nav-bar">
          <Nav className="justify-content-center flex-grow-1">
            <Nav.Item className="position-relative" ref={dropDownRef}>
              <Nav.Link
                className="ms-3 text-black"
                onClick={toggleChevron}
                style={{ fontSize: "18px", fontWeight: "500px" }}
              >
                Services <ChevronDown />
              </Nav.Link>
              {dropDown && (
                <div className="dropdown-menu show">
                  <label
                    htmlFor="doyenifyAgency"
                    onClick={() => handleSelect("/doyenifyAgency")}
                    className="dropdown-item"
                  >
                    Doyenify Agency
                  </label>
                  <label
                    htmlFor="academy"
                    onClick={() =>
                      handleSelect("https://academy.doyenify.com/", true)
                    }
                    className="dropdown-item"
                  >
                    Doyenify Academy
                  </label>
                  <label
                    htmlFor="doyen hr"
                    onClick={() =>
                      handleSelect("https://hr.doyenify.com/", true)
                    }
                    className="dropdown-item"
                  >
                    Doyen HR
                  </label>

                  <label
                    htmlFor="yungdoyens"
                    onClick={() =>
                      handleSelect("https://yung.doyenify.com/", true)
                    }
                    className="dropdown-item"
                  >
                    YungDoyens
                  </label>

                  <label
                    htmlFor="doyenify talents"
                    onClick={() =>
                      handleSelect(
                        "https://chimerical-sunshine-637797.netlify.app/",
                        true
                      )
                    }
                    className="dropdown-item"
                  >
                    Doyenify Talent
                  </label>

                  <label
                    htmlFor="software development"
                    onClick={() => handleSelect("/softwaredevelopment")}
                    className="dropdown-item"
                  >
                    Software Development
                  </label>
                </div>
              )}
            </Nav.Item>

            <Nav.Item className="position-relative" ref={companyDropDownRef}>
              <Nav.Link
                className="ms-3 text-black"
                onClick={toggleCompanyChevron}
                style={{ fontSize: "18px", fontWeight: "500px" }}
              >
                Company <ChevronDown />
              </Nav.Link>
              {companyDropDown && (
                <div className="dropdown-menu show">
                  <label
                    htmlFor="our team"
                    onClick={() => handleSelect("/our_team")}
                    className="dropdown-item"
                  >
                    Our Team
                  </label>
                  <label
                    htmlFor="doyenifyAgency"
                    onClick={() => handleSelect("/doyenifyAgency")}
                    className="dropdown-item"
                  >
                    Doyenify Agency
                  </label>
                </div>
              )}
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                className="ms-3 text-black"
                href="/about_us"
                style={{ fontSize: "18px", fontWeight: "500px" }}
              >
                About Us
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav className="justify-content-end flex-grow-1">
            <Nav.Link
              className="ms-3 text-black"
              href="https://www.linkedin.com/company/doyenify/"
              target="_blank"
            >
              <Linkedin />
            </Nav.Link>
            <Nav.Link
              className="ms-3 text-black"
              href="https://web.facebook.com/profile.php?id=100091606221617"
              target="_blank"
            >
              <Facebook />
            </Nav.Link>
            <Nav.Link
              className="ms-3 text-black"
              href="https://www.instagram.com/doyenify/?igshid=ZDdkNTZiNTM%3D"
              target="_blank"
            >
              <Instagram />
            </Nav.Link>
            <Link to="/contact" target="_blank">
              <Button className="ms-3 nav-links" variant="success">
                Contact us
              </Button>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
