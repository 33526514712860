import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Facebook, Instagram, Linkedin, Twitter } from "react-bootstrap-icons";
import "./footer.css";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
const Footer: React.FC = () => {
  return (
    <div>
      <main>
        <div
          className=""
          style={{ backgroundColor: "#38813C", paddingTop: "20px" }}
        >
          <Container className="footer_container">
            <Row>
              <Col sm={12} md={4} lg={3} className="text-start footer px-3">
                <h5 className="text-white mb-3 mt-5 mx-3">Our Services</h5>
                <p>
                  <span></span>
                </p>
                <Link
                  to="https://academy.doyenify.com/"
                  target="_blank"
                  className="link_tag"
                >
                  <a className="text-white mx-3">Doyenify Academy</a>
                </Link>
                <p>
                  <span></span>
                </p>
                <Link
                  to="https://chimerical-sunshine-637797.netlify.app/"
                  className="link_tag"
                  target="_blank"
                >
                  <a className="text-white mx-3">Doyenify Talents</a>
                </Link>
                <p>
                  <span></span>
                </p>
                <Link
                  to="https://hr.doyenify.com/"
                  target="_blank"
                  className="link_tag"
                >
                  <a className="text-white mx-3">DoyenHR</a>
                </Link>
                <p>
                  <span></span>
                </p>
                <Link
                  to="https://yung.doyenify.com/"
                  target="_blank"
                  className="link_tag"
                >
                  <a className="text-white mx-3">YungDoyens</a>
                </Link>
              </Col>

              <Col sm={12} md={4} lg={3} className="text-start footer px-3">
                <h5 className="text-white mb-3 mt-5 mx-3">Help</h5>
                <p>
                  <span></span>
                </p>

                <Link className="text-white mx-3" to="/contact">
                  Contact us
                </Link>
                <p>
                  <span></span>
                </p>
                <a href="tel:+2348135361806" className="text-white mx-3">
                  +37256704920
                </a>

                <p>
                  <span></span>
                </p>
                <p className="text-white mx-3">Send us a mail</p>
                <p>
                  <span></span>
                </p>
                <a
                  href="mailto:info.doyenify@gmail.com"
                  className="text-white mx-3"
                >
                  info@doyenify.com
                </a>
              </Col>

              <Col sm={12} md={4} lg={3} className="text-start px-3 footer">
                <h5 className="text-white mb-3 mt-5 mx-3"> Company</h5>

                <p>
                  <span></span>
                </p>
                <Link to="/about_us" className="link_tag">
                  <a className="text-white mx-3">About us</a>
                </Link>
                <p>
                  <span></span>
                </p>
                <Link to="#" className="link_tag">
                  <a className="text-white mx-3">Our Portfolio</a>
                </Link>
                <p>
                  <span></span>
                </p>
                <Link to="our_team" className="link_tag">
                  <a className="text-white mx-3">Our Team</a>
                </Link>
              </Col>

              <Col sm={12} md={4} lg={3} className="text-start footer px-3">
                <h5 className="text-white mb-3 mt-5 mx-3"> Find us on </h5>

                <Row>
                  <Col xs={2} md={2} className="px-4">
                    <a
                      href="https://www.instagram.com/doyenify/?igshid=ZDdkNTZiNTM%3D"
                      className="text-white"
                      target="_blank"
                    >
                      <div className="footer-social">
                        <Instagram className="footer-link" />
                      </div>
                    </a>
                  </Col>
                  <Col xs={2} md={2} className="px-4">
                    <a
                      href="https://web.facebook.com/profile.php?id=100091606221617"
                      className="text-white"
                      target="_blank"
                    >
                      <div className="footer-social">
                        <Facebook className="footer-link" />
                      </div>
                    </a>
                  </Col>
                  <Col xs={2} md={2} className="px-4">
                    <a
                      href="https://www.linkedin.com/company/doyenify/"
                      className="text-white"
                      target="_blank"
                    >
                      <div className="footer-social">
                        <Linkedin className="footer-link" />
                      </div>
                    </a>
                  </Col>
                  <Col xs={2} md={2} className="px-4">
                    <a
                      href="https://twitter.com/DOYENIFY?t=iIP1-2SvQTGkMvpng9Fddw&s=09"
                      className="text-white"
                      target="_blank"
                    >
                      <div className="footer-social">
                        <Twitter className="footer-link" />
                      </div>
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <div className="footer-terms pt-2 border-bottom text-white"></div>
          <Row className="row-text footer mx-3">
            <Col
              sm={3}
              md={5}
              lg={6}
              className="text-sm-justify text-md-center"
            >
              <p className="pb-3 px-3 text-white">
                &copy; 2024 Doyenify Limited. All right reserved.
              </p>
            </Col>

            <Col
              sm={3}
              md={4}
              lg={3}
              className="text-sm-justify text-md-center terms"
            >
              <a
                href="/TermsAndConditions"
                target="_blank"
                className="text-white"
              >
                <p className=" term-link spb-2 px-3">Terms and Conditions</p>
              </a>
            </Col>

            <Col
              sm={3}
              md={3}
              lg={3}
              className="text-sm-justify text-md-center terms"
            >
              <a href="/PrivacyPolicy" target="_blank" className="text-white">
                <p className=" privacy-link pb-2 px-3">Privacy Policy</p>
              </a>
            </Col>
          </Row>
          {/* WhatsApp icon display and link */}
          <div className=" d-flex justify-content-end whatsapp-icon animate-bounce">
            <a
              href="https://api.whatsapp.com/message/LOSGHDCE2XGBL1?autoload=1&app_absent=0"
              target="_blank"
            >
              <img
                className="whatsapp"
                src="./img/whatsapp.png"
                alt="Whatsapp-icon"
              />
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Footer;
